<template>
  <div class="container">
    <div class="select-box">
        <h2 class="select-title">产品分析表</h2>

      <div class="show-content" v-show="showRequestFlag">
          <div class="select-top">

        <div class="select-date-box">
          <div class="select-item">
            <span class="select-desc">开始时间:</span>
            <el-date-picker
              v-model="value2"
              align="right"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="select-item">
            <span class="select-desc">结束时间:</span>
            <el-date-picker
              v-model="value2"
              align="right"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
            <wd-select-picker v-model="cvalue1" :columns="columns" filterable></wd-select-picker>
          </div>
        </div>
        <div class="select-item">
          <div class="select-inner">
            <span class="select-desc">销量指标:</span>
            <input class="indicators-content" v-model="xiaoliang" type="number" />
        </div>
        <div class="select-inner">
            <span class="select-desc">毛利指标:</span>
            <input class="indicators-content" v-model="maoli" type="number" />
          </div>
        </div>
      </div>

      <div class="select-content">
        <div class="select-item">
          <span class="select-desc">总公司:</span>
          <wd-select-picker v-model="cvalue1" :columns="columns" filterable></wd-select-picker>
        </div>
        <div class="select-item">
          <span class="select-desc">大区:</span>
          <wd-select-picker v-model="cvalue1" :columns="columns" filterable></wd-select-picker>
        </div>
        <div class="select-item">
          <span class="select-desc">业务员:</span>
          <wd-select-picker v-model="cvalue1" :columns="columns" filterable></wd-select-picker>
        </div>
        <div class="select-item">
          <span class="select-desc">区域:</span>
          <wd-select-picker v-model="cvalue1" :columns="columns" filterable></wd-select-picker>
        </div>
        <div class="select-item shop">
          <span class="select-desc">门店:</span>
          <wd-select-picker v-model="cvalue1" :columns="columns" filterable></wd-select-picker>
          <wd-checkbox value="shop">商家后台</wd-checkbox>
        </div>
      </div>

      <div class="select-content">
        <div class="select-item">
          <span class="select-desc">分类:</span>
          <wd-select-picker v-model="cvalue1" :columns="columns" filterable></wd-select-picker>
        </div>
        <div class="select-item">
          <span class="select-desc">厂家:</span>
          <wd-select-picker v-model="cvalue1" :columns="columns" filterable></wd-select-picker>
        </div>
        <div class="select-item">
          <span class="select-desc">品牌:</span>
          <wd-select-picker v-model="cvalue1" :columns="columns" filterable></wd-select-picker>
        </div>
        <div class="select-item product">
          <span class="select-desc">产品:</span>
          <wd-select-picker v-model="cvalue1" :columns="columns" filterable></wd-select-picker>
          <wd-checkbox-group v-model="value">
  <wd-checkbox value="jingmai">京麦</wd-checkbox>
  <wd-checkbox value="shop">商家后台</wd-checkbox>
</wd-checkbox-group>
        </div>
      </div>
      </div>

      <div v-if="showRequestFlag" class="operate-box" @click="showRequestFlag=false">
    <i class="el-icon-d-arrow-left"></i>
    收起查询面板
    <i class="el-icon-d-arrow-left"></i>
    </div>
    <div v-else  class="operate-box" @click="showRequestFlag=true">
    <i class="el-icon-d-arrow-right"></i>
    展开查询面板
    <i class="el-icon-d-arrow-right"></i>
    </div>
    </div>
    <div class="table-box">
      <div class="table-left">
        <el-table
          :data="productList"
          border
          :style="productList.length == 0 ? 'height:100%' : ''"
          v-loading="loading"
          height="400px"
          size="small"
        >
          <el-table-column prop="Zcpmc" label="产品ID" width="80">
          </el-table-column>
          <el-table-column prop="ZkcT" label="分类ID" width="80">
          </el-table-column>
          <el-table-column prop="Zkxts" label="分类名" width="100">
          </el-table-column>
          <el-table-column prop="Zxzl" label="厂家ID" width="80">
          </el-table-column>
          <el-table-column prop="cpmc" label="厂家名" width="100">
          </el-table-column>
          <el-table-column prop="kcT" label="品牌ID" width="100">
          </el-table-column>
          <el-table-column prop="kxts" label="可销天数" width="100">
          </el-table-column>
          <el-table-column prop="xzl" label="品牌" width="100">
          </el-table-column>
          <el-table-column label="产品名" width="120"> </el-table-column>
        </el-table>
      </div>
      <div class="table-right">
        <el-table
          :data="productList"
          border
          style="width: 1141px"
          :style="productList.length == 0 ? 'height:100%' : ''"
          v-loading="loading"
          height="400px"
          size="small"
        >
          <el-table-column prop="Zcpmc" label="总公司ID" width="80">
          </el-table-column>
          <el-table-column prop="ZkcT" label="总公司" width="100">
          </el-table-column>
          <el-table-column prop="Zkxts" label="大区ID" width="80">
          </el-table-column>
          <el-table-column prop="Zxzl" label="大区" width="120">
          </el-table-column>
          <el-table-column prop="cpmc" label="业务员ID" width="80">
          </el-table-column>
          <el-table-column prop="kcT" label="业务员" width="100">
          </el-table-column>
          <el-table-column prop="kxts" label="区域" width="120">
          </el-table-column>
          <el-table-column prop="xzl" label="门店ID" width="80">
          </el-table-column>
          <el-table-column label="门店名称" width="100"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment';

export default {
  data() {
    return {
        showRequestFlag:true,
        cvalue1:'',
        columns:[
            {
          value: '101',
          label: '男装'
        },
        {
          value: '102',
          label: '奢侈品'
        },
        {
          value: '103',
          label: '女装'
        }
        ],
      productList: [],
      customerList: [],
      options: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      value: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value1: "",
      value2: "",
    };
  },
  mounted(){
      let list = this.storage.get('list');
      let lastDate = this.storage.get('lastDate');
      if (list) {
          var nowDate = moment(new Date());
        if(nowDate.diff(lastDate, 'days')>=4){
            //重新存储 或清空  storage.set('list',this.list}  storage.remove('list')
        }
      }else{
          //存储
      }

  },
  methods: {},
};
</script>

<style scoped lang="scss">
.container {
  // width: 100%;
  // height: 100%;
//   background: #fff;
  overflow: auto;
//   padding: 40px;
  background-color: #409eff; /* 浏览器不支持时显示 */
  background-image: linear-gradient(#409eff,#409eff,#fff);
  .select-title{
      background:#409eff;
      margin: 0;
      padding: 22px 0 12px;
      color: #fff;
  }
  .select-box {
    display: flex;
    flex-direction: column;
    background: #fff;
    .select-top,
    .select-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .select-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #eee;
      }
    }
    .select-top {
      justify-content: space-between;

      .select-date-box,
      .select-indicators-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        // width: 42%;
        width: 100%;
      }
      .select-indicators-box{
          flex-direction:row;
          justify-content: space-around;
      }
    }
    .select-content{
        // margin-bottom: 20px;
    }
  }
  .table-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .table-left,
    .table-right {
      width: 92%;
    //   max-width: 862px;
    //   margin-bottom: 20px;
      margin: 20px 0;
    }
  }
}
// .container /deep/ .el-input {
//   width: 70%;
// }
.select-desc {
  display: block;
  width: 80px;
  text-align: right;
  flex-shrink: 0;
}
.indicators-content{
    background-color:#fff;
    width: 100px;
    height:30px;
    line-height: 30px;
    border-radius: 6px;
}
.container /deep/ .el-input__inner{
    border: none;
    width: 100%;
}
.container /deep/ .el-input{
    width: 100%;
}
.container /deep/ .el-select{
    width:100%;
}
.container /deep/ .wd-select-picker{
    flex-shrink: 0;
    // width:100%;
}
.select-content /deep/ .wd-col-picker{
    width: calc(100vw - 80px);
}
.product /deep/ .wd-col-picker{
    width: calc(100vw - 260px);
}
.shop /deep/ .wd-col-picker{
    width: calc(100vw - 200px);
}
.select-content /deep/ .wd-col-picker__value{
    text-align:left;
}
.container /deep/ .wd-icon-arrow-right{
    flex-shrink: 0;
}
.container /deep/ .wd-checkbox-group{
    display: flex;
    flex-direction:row;
    align-items: center;
}
.container /deep/ .wd-checkbox{
    margin-bottom: 0;
    margin-left: 6px;
}
.operate-box{
  padding: 4px;
  color: #fff;
  background-image: linear-gradient(to bottom,rgba(240,242,245,.4),rgba(202,205,207,.4),rgba(240,242,245,.4));
  cursor: pointer;
}
.el-icon-d-arrow-left,.el-icon-d-arrow-right{
  transform: rotate(90deg);
}
.show-content{
    transition: all linear 1.5s;
}
.select-inner{
    display: flex;
    flex-direction: row;
    align-items:center;
    height:50px;
    line-height: 48px;
    .indicators-content{
        font-size: 16px;
        height:48px;
        padding-left: 6px;
    }
    }
</style>
