export default [
    {
        BiaoQianChanPinXuanXiang:['强配','配送','厂家处理','预淘汰'],
        BiaoQianKeHuXuanXiang:['装修','关门','学校店','收款困难'],
        //日期天数
        dayNum:[{value:7,},{value:15,},{value:30,},{value:60,},{value:90,},{value:180,},{value:365,}],
        //大区
        companyListWT:[{value:"卖场",id:"1",},{value:"连锁",id:"2",},{value:"直营批发",id:"3",}],
        companyListMS:[{value:"卖场组/伊利",id:"9001",},{value:"卖场借户/卡士",id:"9002",},{value:"传统组",id:"9003",},{value:"连锁组",id:"9004",},{value:"特通组",id:"9005",},{value:"特通组（二批/直属）",id:"9006",},{value:"其他（瓶盖/样品）",id:"9007",},{value:"报废退货",id:"9008",},{value:"万泰（波力）",id:"9009",},{value:"精品超市",id:"9019",},{value:"精品超/餐饮（直送）",id:"100095",},],
        companyListZS:[{value:"卖场",id:"1",},{value:"连锁",id:"2",},{value:"批发市场",id:"3",},{value:"配送商",id:"4",},{value:"分销商",id:"5",},{value:"零售",id:"6",},{value:"直营",id:"7",},{value:"车销",id:"8",},{value:"费用客户",id:"9",},{value:"舟山加贝超市",id:"572",},{value:"特供批发",id:"944",},],

        //客户分析-
        listKHBiao:{addBtn:false,cellBtn:false,card:false,delBtn:false,editBtn:false,refreshBtn:false,selectionFixed:false,searchBtn:false,
            menu:false,showSummary:true,columnBtn:false,fit:true,border:true,index:true,header:false,
            height:280,sizeValue:'mini',stripe:true,
            column:[{prop:"khmc",label:"门店名称",width:"200",sortable:true,slot:true},
            {prop:"PuShiShu",label:"产品数",width:"80",slot:true,sortable:true},
            {prop:"bfcs",label:"拜访数",width:"80",slot:true,sortable:true},
            {prop:"XSje",label:"销量",width:"80",sortable:true},
            {prop:"XSml",label:"毛利",width:"80",sortable:true},
            {prop:"mll",label:"毛利率",width:"80",sortable:true}
            ,{prop:"thl",label:"退货率",width:"80",sortable:true},
            {prop:"zhdh",label:"最后订单",width:"120",sortable:true}],
            sumColumnList: [{name: 'XSje',type: 'sum',},{name: 'XSml',type: 'sum'}],
        },
        listKHFenJieBiao:{addBtn:false,cellBtn:false,card:false,delBtn:false,editBtn:false,refreshBtn:false,selectionFixed:false,searchBtn:false,
            menu:false,showSummary:true,columnBtn:false,fit:true,border:true,index:true,header:false,
            height:280,sizeValue:'mini',stripe:true,
            column:[{prop:"cpmc",label:"产品名称", sortable:true,slot:true},
            {prop:"cs",label:"下单次数",width:"80", sortable:true},
            {prop:"XSje",label:"销量",width:"80",sortable:true},
            {prop:"XSml",label:"毛利",width:"80",sortable:true}
           ],
            sumColumnList: [{name: 'XSje',type: 'sum',},{name: 'XSml',type: 'sum'}],
        },
        //产品分析
        listCPBiao:{addBtn:false,cellBtn:false,card:false,delBtn:false,editBtn:false,refreshBtn:false,selectionFixed:false,searchBtn:false,
            menu:false,showSummary:true,columnBtn:false,fit:true,border:true,index:true,header:false,
            height:280,sizeValue:'mini',stripe:true,
            column:[{prop:"cpmc",label:"产品名称",width:"200",sortable:true,slot:true},
            {prop:"PuShiShu",label:"客户数",width:"80",slot:true,sortable:true},
            {prop:"XSje",label:"销量",width:"80",sortable:true},
            {prop:"XSml",label:"毛利",width:"80",sortable:true},
            {prop:"mll",label:"毛利率",width:"80",sortable:true}
            ,{prop:"thl",label:"退货率",width:"80",sortable:true}, ],
            sumColumnList: [{name: 'XSje',type: 'sum',},{name: 'XSml',type: 'sum'}],
        },
        listCPFenJieBiao:{addBtn:false,cellBtn:false,card:false,delBtn:false,editBtn:false,refreshBtn:false,selectionFixed:false,searchBtn:false,
            menu:false,showSummary:true,columnBtn:false,fit:true,border:true,index:true,header:false,
            height:280,sizeValue:'mini',stripe:true,
            column:[{prop:"dwjc",label:"门店名称", sortable:true,slot:true},
            {prop:"cs",label:"下单次数",width:"80", sortable:true},
            {prop:"XSje",label:"销量",width:"80",sortable:true},
            {prop:"XSml",label:"毛利",width:"80",sortable:true}
           ],
            sumColumnList: [{name: 'XSje',type: 'sum',},{name: 'XSml',type: 'sum'}],
        }




    }]
