<template>
  <div class="container">
    <div class="select-box">
      <div class="select-top">
        <h3>7天可销售产品清单</h3>
      </div>
      <div class="show-content" v-show="showRequestFlag">
        <div class="select-content select-content2">
          <div class="select-item mendian-item">

          </div>
           <div class="select-item">
            <span class="select-desc">可销天数:</span>
            <!-- <input type="number" /> -->
            <input
              class="indicators-content"
              type="number"
              v-model="TianShu"
            />

          </div>
  <el-checkbox
              v-model="BaoHanMianZe"
              size="mini"
              label="包含免责"
              border
            ></el-checkbox>
          <el-button type="primary" size="mini" @click="toQuery"
            >查询</el-button>

          <el-button type="warning" size="mini" @click="ChaKanGongYingShang"
            >免责的厂家</el-button>
          <el-button type="warning" size="mini" @click="ChaKanChanPin"
            >免责的产品</el-button>

          <el-button type="info" size="mini" @click="exportNewList"
            >导出</el-button>
        </div>
      </div>


    </div>

    <div class="table-box">
      <div class="table-left">
        <el-table
          :data="ZongBiao"
          border
          show-summary
          @row-click="rowClick"
          v-loading="tableLTloading"
          size="mini"
        height="600px"
        >
          <el-table-column type="index"> </el-table-column>

          <el-table-column prop="cpbh" sortable label="编号"  width="70" >
          </el-table-column>
         <el-table-column prop="cpmc" sortable label="产品名称" width="230">
           <template slot-scope="scope">
                <p class="content-detail">{{scope.row.cpmc}}</p>
                <div class="content-tag">
                  <el-tag type="success" size="mini" v-for="bq in GetBiaoQian(scope.row.BiaoQian)"
                  :key="bq"  >{{bq}}</el-tag>
                  <i class="el-icon-plus" v-if="BiaoChanPin" @click="DaBiaoQian(scope.row)"></i>
                </div>
              </template>
          </el-table-column>

          <el-table-column  prop="GenZong"  sortable label="跟踪数" width="80">
             <template slot-scope="scope">
               <el-tag size="medium" v-show="scope.row.GenZong>0" >{{ scope.row.GenZong }}</el-tag>
               <span v-show="scope.row.GenZong==0" >※</span>
            </template>
          </el-table-column>
          <el-table-column prop="ggxh" sortable label="规格"  width="80">
          </el-table-column>

          <el-table-column prop="sjkyxs" sortable label="箱数"  width="80">
          </el-table-column>

          <el-table-column prop="sjkyl" sortable label="可用量"  width="80" >
          </el-table-column>
          <el-table-column prop="gys" sortable label="供应商"  width="80" >
          </el-table-column>
          <el-table-column prop="yxs" sortable label="日平均出库量"  width="80" >
          </el-table-column>
          <el-table-column prop="ts" sortable label="可销天"  width="80">
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="table-box table-user">
      <div class="block" v-for="ck in ckdtList" :key="ck.id">
        <el-avatar size="small" :src="ck.headIcon" class="ava"></el-avatar>
        <div class="name">{{ ck.username }}</div>
        <span class="time">{{ ck.clicksj }}</span>
      </div>
    </div>

     <el-dialog title="免责的供应商" :visible.sync="MianZeDeGongYingShang">
        <el-table
          :data="MianZeDeGongYingShangDT"
          border
          show-summary
          height="280px"
          size="mini"
        >
         <el-table-column      type="index" >
    </el-table-column>
          <el-table-column prop="gysbh" label="编号">
             </el-table-column>
          <el-table-column prop="YuanYin" label="名称">
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="MianZeDeGongYingShang = false">关 闭</el-button>
        </div>
      </el-dialog>


     <el-dialog title="免责的产品" :visible.sync="MianZeDeChanPin">
        <el-table
          :data="MianZeDeChanPinDT"
          border
          show-summary
          height="280px"
          size="mini"
        >
         <el-table-column      type="index" >
    </el-table-column>
          <el-table-column prop="cpbh" label="编号">
             </el-table-column>
          <el-table-column prop="YuanYin" label="名称">
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="MianZeDeChanPin = false">关 闭</el-button>
        </div>
      </el-dialog>


     <el-dialog title="产品跟踪" :visible.sync="ChanPinGenZong">
        <el-table
          :data="ChanPinGenZongDT"
          border
          show-summary
          height="280px"
          size="mini"
        >
         <el-table-column      type="index" >
    </el-table-column>
          <el-table-column prop="ZhuangTai" label="状态">
             </el-table-column>
          <el-table-column prop="JieGuo" label="结果">
          </el-table-column>

          <el-table-column prop="ShiJian" label="时间">
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
           <el-button type="primary" @click="ChanPinGenZongAdd=true">增 加</el-button>
          <el-button @click="ChanPinGenZong = false">关 闭</el-button>
        </div>
      </el-dialog>

     <el-dialog title="增加跟踪" :visible.sync="ChanPinGenZongAdd">
       <div class="select-box">
        <div class="select-content">
          <div class="select-item">
            <span class="select-desc">状态:</span>
            <el-select
              v-model="ZhuangTai"
              size="mini"
              default-first-option
              placeholder="请选择状态"
            >
              <el-option
                v-for="item in ZhuangTaiList"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
   <div class="select-item product-item">
            <span class="select-desc">备注:</span>
            <input
              type="text"
              class="product-input"
              v-model="JieGuo"
              placeholder="请填写备注"
            />

  </div>
        </div>


       </div>

        <div slot="footer" class="dialog-footer">
           <el-button type="primary" @click="BaoCunGenZong">保 存</el-button>
          <el-button @click="ChanPinGenZongAdd = false">关 闭</el-button>
        </div>
      </el-dialog>
<div class="select-dialog-outer">
      <el-dialog title="标签选择" :visible.sync="BiaoQiandiv">
        {{BiaoQianname}}
        <el-checkbox-group v-model="XuanZhongBiaoQian">
    <el-checkbox :label="item" v-for="(item,index) in BaoBiaoCom.BiaoQianChanPinXuanXiang" :key="index"></el-checkbox>
  </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="QueRenBiaoQian">确 认</el-button>
          <el-button @click="BiaoQiandiv = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import * as moment from "moment";
import XLSX from "xlsx";
import BaoBiao from "@/common/BaoBiao.js";
export default {
  data() {
    return {
      dialogFormVisible: false,
      BaoHanMianZe: false,
      tableLTloading: false,
      showRequestFlag: true,
      TianShu:7,
      XuanZhongBiaoQian:[],//标签选中列表
      BiaoQiandiv:false,
      BiaoQianname:'',
      BiaoQiancpid:'',
      BiaoChanPin:false,
       BaoBiaoCom:[],
      ZongBiao: [],
      ckdtList:[],
      listItems: [
        { label: "编号" },
        { label: "名称" },
        { label: "规格" },
        { label: "箱数" },
        { label: "可用量" },
        { label: "供应商" },
        { label: "日平均出库量" },
        { label: "可销天" },
      ],
      MianZeDeGongYingShang:false,
      MianZeDeGongYingShangDT:[],
      MianZeDeChanPin:false,
      MianZeDeChanPinDT:[],
      ChanPinGenZong:false,
      ChanPinGenZongDT:[],
      ChanPinGenZongAdd:false,
       ZhuangTai: "",
       BianHao: "",
       JieGuo:"",
      ZhuangTaiList: [
        {
          value: "通知厂方处理"
        },
        {
          value: "通知业务处理"
        },
        {
          value: "无法处理"
        },
      ],
      NBWanStoken: "",
      where: {},
    };
  },
  mounted() {
    this.NBWanStoken = this.storage.get("userToken");
    if (!this.NBWanStoken) {
      this.$router.push("/pcLogin");
    }
    this.BaoBiaoCom=BaoBiao[0];

    if( this.storage.get("BiaoChanPin")=='1'){//标产品的权限
       this.BiaoChanPin=true
    }
  },
  methods: {
      //展示标签选择弹窗
    DaBiaoQian(row){
      this.XuanZhongBiaoQian=this.GetBiaoQian(row.BiaoQian);
      this.BiaoQianname=row.cpmc;
      this.BiaoQiancpid=row.cpbh;
      this.BiaoQiandiv = true;
    },
    //标签选择确认
    QueRenBiaoQian(){
      console.log(this.XuanZhongBiaoQian);
           this.axios
            .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
              params: {
                method:'BiaoQianChanPin',
                NBWanStoken:this.NBWanStoken,
                cpbh:this.BiaoQiancpid,
                BiaoQian:this.XuanZhongBiaoQian.join("|")

              },
            })
            .then((res) => {
              if (res.data.Success) {
                 this.BiaoQiandiv = false;
              } else {
                alert(res.data.Message);
              }
            });
    },
    GetBiaoQian(bpT){
      if(bpT){
        var bps=[];
        bps = bpT.split("|");
        return bps;
      }else{
        return [];
      }
    },
    //点击表格行触发事件
    //row 包含该行中所有的数据
    rowClick(row, column, event) {
      switch (column.property) {
        case "GenZong":
          this.ChanPinGenZongDT=[];
          this.ChanPinGenZong = true;
          this.BianHao=row.cpbh;
          this.axios
            .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
              params: {
              method: "GETChanPinGenZong",
              LeiXing:'TianKeXiao',
              BianHao:this.BianHao,
              NBWanStoken: this.NBWanStoken,
            },
            })
            .then((res) => {
              if (res.data.Success) {
                this.ChanPinGenZongDT = JSON.parse(res.data.Data);

              } else {
                //alert(res.data.Message);
              }
            });
          console.log(row.cpbh);

          break;
      }
      //console.log(row,column,event);
    },
    //弹出窗的导出
    exportDialog() {},
    exportNewList() {
      //基础设置
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var tabHandle = []; //导出表头
      // this.listItems 是表头数据
      this.listItems.forEach((item) => {
        tabHandle.push(item.label);
      }); //表头数组
      let tableData2 = [];
      tableData2.push(tabHandle);

      this.ZongBiao.forEach((item, index) => {
        let rowData = [];
        //导出内容的字段
        rowData = [
          item.cpbh,
          item.cpmc,
          item.ggxh,
          item.sjkyxs,
          item.sjkyl,
          item.gys,
          item.yxs,
          item.ts,
        ];
        tableData2.push(rowData);
      });

      let workSheet2 = XLSX.utils.json_to_sheet(tableData2, xlsxParam);

      let bookNew = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(bookNew, workSheet2, "7天可销售产品清单");

      let name = "7天可销售产品清单.xlsx"; //表格名称
      XLSX.writeFile(bookNew, name); // 保存的文件名
    },

    // 错误消息提示
    openError(errMessage) {
      this.$message({
        showClose: true,
        message: errMessage,
        type: "warning",
      });
    },
    //查询
    toQuery() {

      this.ZongBiao = [];
      this.tableLTloading = true;

      this.where = {
        method: "TianKeXiao",
        BaoHanMianZe: this.BaoHanMianZe ? "1" : "0",
        TianShu:this.TianShu,
        NBWanStoken: this.NBWanStoken,
      };

      this.axios
        .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
          params: this.where,
        })
        .then((res) => {
          // console.log(res);

          this.tableLTloading = false;
          if (res.data.Success) {
            this.ZongBiao = JSON.parse(res.data.Data);
            this.ckdtList = JSON.parse(res.data.Data2);
          } else {
            alert(res.data.Message);
          }
        });
    },
    BaoCunGenZong(){//保存跟踪
           if( this.ZhuangTai!=''){
            this.axios
              .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
                params: {
                  method: "BaoCunGenZong",
                   LeiXing:'TianKeXiao',
                  BianHao:this.BianHao,
                  ZhuangTai:this.ZhuangTai,
                  JieGuo:this.JieGuo,
                  NBWanStoken: this.NBWanStoken,
                },
              })
              .then((res) => {
                if (res.data.Success) {
                   this.ChanPinGenZong = false
                   this.ChanPinGenZongAdd = false
                } else {
                  alert(res.data.Message);
                }
              });

           }

    },
    ChaKanGongYingShang(){//查看供应商
      this.MianZeDeGongYingShang=true;
      this.axios
          .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
            params: {
              method: "MianZeDeGongYingShang",
              NBWanStoken: this.NBWanStoken,
            },
          })
          .then((res) => {
            if (res.data.Success) {
              this.MianZeDeGongYingShangDT = JSON.parse(res.data.Data);

            } else {
              alert(res.data.Message);
            }
          });
      },
        ChaKanChanPin(){//查看产品
      this.MianZeDeChanPin=true;
      this.axios
          .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
            params: {
              method: "MianZeDeChanPin",
              NBWanStoken: this.NBWanStoken,
            },
          })
          .then((res) => {
            if (res.data.Success) {
              this.MianZeDeChanPinDT = JSON.parse(res.data.Data);

            } else {
              alert(res.data.Message);
            }
          });
      },
  },
};
</script>

<style scoped lang="scss">
.container {
  // width: 100%;
  // background: #fff;
  // padding: 40px;
  // background-color: #409eff; /* 浏览器不支持时显示 */
  // background-image: linear-gradient(#409eff,#409eff,#fff);
  // min-width: 1200px;

  background: #f9fafc;
  .select-box {
    display: flex;
    flex-direction: column;
    // background:#eee;
    .select-top,
    .select-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 5px;
      .select-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 200px;
      }
    }
    .select-top {
      // background-color: #409eff;

      color: #333;
      justify-content: space-around;

      .select-date-box,
      .select-indicators-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 495px;
      }
      .select-indicators-box {
        justify-content: flex-end;
      }
    }
    .select-content {
      margin: 5px 0;

      .mendian-item {
        width: 40%;
      }
    }
    .select-content2 {
      .select-item {
        width: 20%;
        &:last-child {
          width: 40%;
        }
        .check-box {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .mendian-item{
        width: 40%;
      }
    }
  }
  .table-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 80px);
    padding: 0 40px;
    // margin: 0px 0;
    .table-left,
    .table-right {
      width: 100%;
      // max-width: 862px;
      // margin: 40px;
    }
    // .table-left {
    //   margin-left: 30px;
    // }
    // .table-right {
    //   margin-right: 30px;
    // }
  }
}
.select-desc {
  display: block;
  width: 80px;
  text-align: right;
  flex-shrink: 0;
}
.indicators-content {
  background-color: #fff;
  width: 110px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
  padding-left: 6px;
}

.day-num-box /deep/ .el-input {
  width: 90px;
}
.day-num-box {
  margin-left: 20px;
}
.date-box /deep/ .el-date-editor.el-input {
  width: 100%;
  // min-width: 120px;
}
.date-box /deep/ .el-input--suffix .el-input__inner {
  padding-right: 0;
}
// .check-box {
//   margin-left: 20px;
// }
.check-box /deep/ .el-checkbox.is-bordered {
  margin-right: 0;
}
.select-content /deep/ .el-checkbox {
  margin-left: 5px;
}
.container /deep/ .el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.operate-box {
  padding: 10px;
  color: #fff;
  // color: #333;
  // background-image: linear-gradient(
  //   to bottom,
  //   rgba(240, 242, 245, 0.4),
  //   rgba(202, 205, 207, 0.4),
  //   rgba(240, 242, 245, 0.4)
  // );
  background: #66b1ff;
  cursor: pointer;
}
.el-icon-d-arrow-left,
.el-icon-d-arrow-right {
  transform: rotate(90deg);
}
.show-content {
  // background: rgba(255,255,255,.4);
  background: #f9fafc;
}
.btns {
  margin-bottom: 20px;
}
.product-input,
.mendian-input {
  width: 100%;
  height: 38px;
  border: 1px solid #eee;
  border-radius: 6px;
  padding-left: 10px;
  &::placeholder {
    color: #c8c9cc;
  }
}
.table-title {
  width: 100%;
  position: relative;

  border-bottom: 1px solid #eee;
  .table-download {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
}
.container /deep/ .el-select {
  width: 100%;
}
.product-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40% !important;
}
.select-content /deep/ .el-button {
  margin-left: 5px;
}
.table-box /deep/ .cell {
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table-box /deep/ .cell  .content-detail{
      margin-top: 9px;
}
.table-box /deep/ .cell  .content-tag{
      position: absolute;
    top: 0px;
    right: 0px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.table-box /deep/ .cell  .content-tag .el-tag{
    margin-right: 3px;
    font-size: 8px;
}
.table-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ava,
    .name,
    .time {
      margin-top: 3px;
    }
    .name {
      font-size: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .time {
      font-size: 8px;
      padding: 3px;
    }
  }
}
.select-dialog-outer /deep/ .el-dialog{
  width:360px;
}
.select-dialog-outer /deep/ .el-dialog .dialog-footer{
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: space-between;
}
.select-dialog-outer /deep/ .el-checkbox-group{
  display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 10px;
}
.select-dialog-outer /deep/ .el-dialog__body{
  padding: 10px 20px;
}
</style>
