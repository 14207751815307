<template>
  <div class="container-box">
    <div class="content" :class="isCollapse ? '' : 'foldContent'">
      <div class="content-top">
        <!-- <div class="top-desc">
          数据真正的价值是<span style="color: red">分析出问题</span>并<span
            style="color: red"
            >进行整改</span
          >
        </div> -->
        <el-tabs
          v-model="editableTabsValue"
          type="card"
          :closable="false"
          @tab-remove="removeTab"
          @tab-click="tabClick"
        >
          <el-tab-pane
            v-for="(item, index) in editableTabs"
            :key="item.name"
            :label="item.title"
            :name="item.name"
            :closable="item.name != MoRenYe"
          >
          </el-tab-pane>
        </el-tabs>
        <div class="avator-outer">
          <el-popover placement="bottom" width="40" trigger="hover">
            <ul class="toolTip">
              <li class="logout-btn" @click="logout">退出登录</li>
            </ul>

            <div class="avator-box" slot="reference">
              <img :src="Udata.CanShu2" alt="" />
            </div>
          </el-popover>

          <div>{{ Udata.CanShu1 }}</div>
          <el-button
            type="primary"
            class="data-center"
            icon="el-icon-data-line"
            @click="toDataCenter"
            >打开报表</el-button
          >
        </div>
      </div>
      <div class="content-inner">
        <component
          v-bind:is="item"
          v-for="(item, index) in componentList"
          :key="index"
          v-show="item == currentTabComponent"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import pcReport from "@/components/pc/report";
import pcReportMS from "@/components/pc/reportMS";
import pcReportZS from "@/components/pc/reportZS";
import KuCunChaXun from "@/components/pc/KuCunChaXun";
import laundrylist from "@/components/pc/laundrylist";
import TianKeXiao from "@/components/pc/TianKeXiao";
import SanFenZhiYiGenZong from "@/components/pc/SanFenZhiYiGenZong";
import PivotTable from "@/components/pc/PivotTable";

import KeHuSiXiang from "@/components/pc/KeHuSiXiang";
import KeHuSiXiangMS from "@/components/pc/KeHuSiXiangMS";
import KeHuSiXiangZS from "@/components/pc/KeHuSiXiangZS";
import GeXiang from "@/components/pc/index";
export default {
  name: "pcContent",
  components: {
    pcReport,
    GeXiang,
    pcReportZS,
    pcReportMS,
    KuCunChaXun,
    laundrylist,
    TianKeXiao,
    SanFenZhiYiGenZong,
    KeHuSiXiang,
    KeHuSiXiangMS,
    KeHuSiXiangZS,
    PivotTable,
  },
  data() {
    return {
      componentList: [],
      currentTabComponent: "",
      isCollapse: true,
      Udata: {},
      NBWanStoken: "",
      QuanXian: [],
      QXdt: [],
      MoRenYe: "",
      editableTabsValue: "产品四象限分析",
      editableTabs: [],
      tabIndex: 0,
    };
  },
  activated() {
    setTimeout(() => {
      if (
        this.$route.params.neme != "undefined" &&
        this.$route.params.title != "undefined"
      ) {
        this.DaKai(this.$route.params.neme, this.$route.params.title);
      }
    }, 200);
  },

  mounted() {


    this.NBWanStoken = this.storage.get("userToken");
    if (!this.NBWanStoken) {
      this.$router.push("/pcLogin");
    }
    // const loading = this.$loading({
    //   lock: true,
    //   text: "正在加载中",
    //   spinner: "el-icon-loading",
    //   background: "rgba(0, 0, 0, 0.7)",
    // });
    // loading.close();
    this.QXdt = JSON.parse(this.storage.get("QXdt"));
    this.QuanXian = JSON.parse(this.storage.get("QuanXian"));
    // if (this.QXdt[0].MoRenShouYe) {
    //   this.currentTabComponent = this.QXdt[0].MoRenShouYe;
    //   this.MoRenYe = this.QXdt[0].MoRenShouYe;

    //   this.storage.set("BiaoChanPin", this.QXdt[0].BiaoChanPin);
    //   this.storage.set("BiaoKeHu", this.QXdt[0].BiaoKeHu);

    //   // this.addTab('我的报表',this.QXdt[0].MoRenShouYe);
    // } else {
    //   this.MoRenYe = "pcReport";
    //   this.currentTabComponent = "pcReport";
    //   this.addTab("产品四象限分析", "pcReport");
    // }

    // this.axios
    //   .get(
    //     `${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx?method=getHuiYuanSJZX&NBWanStoken=` +
    //       this.NBWanStoken
    //   )
    //   .then((res) => {
    //     loading.close();
    //     if (res.data.Success) {
    //       this.Udata = res.data;
    //       //this.watermark({ text: this.Udata.CanShu1 ,fontSize:'20px'});
    //       this.QXdt = JSON.parse(res.data.Data);

    //       this.QuanXian = this.QXdt[0].QuanXian.split(",");
    //       if (this.QXdt[0].MoRenShouYe) {
    //         this.currentTabComponent = this.QXdt[0].MoRenShouYe;
    //         this.MoRenYe = this.QXdt[0].MoRenShouYe;

    //         this.storage.set("BiaoChanPin", this.QXdt[0].BiaoChanPin);
    //         this.storage.set("BiaoKeHu", this.QXdt[0].BiaoKeHu);

    //         // this.addTab('我的报表',this.QXdt[0].MoRenShouYe);
    //       } else {
    //          this.MoRenYe ="pcReport";
    //         this.currentTabComponent = "pcReport";
    //         this.addTab('产品四象限分析','pcReport');
    //       }
    //     } else {
    //       this.storage.set("userToken", "");
    //       this.$message({
    //         message: res.data.Message,
    //         type: "warning",
    //       });
    //       this.$router.push("/pcLogin");
    //     }
    //   });
  },
  methods: {
    toDataCenter() {
      this.$router.push("/");
    },
    tabClick(e) {
      this.currentTabComponent = e.name;
    },
    //新增tab
    addTab(tagTitle, targetName) {
      let newTabName = ++this.tabIndex + "";
      this.editableTabs.push({
        title: tagTitle,
        name: targetName,
      });
      // console.log(newTabName);
      this.editableTabsValue = targetName;
      // console.log(this.editableTabsValue);
    },
    //删除tab
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            } else {
              this.$router.push("/");
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.currentTabComponent = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
    },
    //点击退出
    logout() {
      this.storage.set("userToken", "");

      this.$router.push("/pcLogin");
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    DaKai(neme, title) {
      let t = this.componentList.findIndex((item) => {
        return item == neme;
      });
      if (t == -1) {
        this.componentList.push(neme);
      }
      //console.info(this.QuanXian);
      if (this.QuanXian && this.QuanXian.length > 0) {
        if (this.QuanXian.indexOf(neme) != -1) {
          this.currentTabComponent = neme;
          
            var dValue = { title: title, name: neme };
            if (
              JSON.stringify(this.editableTabs).indexOf(
                JSON.stringify(dValue)
              ) === -1
            ) {
              this.addTab(title, neme);
              this.$message("正在为您打开");
            } else {
              this.editableTabsValue = neme;
            }
           
        } else {
          this.$message({
            message: "您好，您没有当前菜单的权限。",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "您好，管理员未给您配置权限。",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.container-box {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
  .slide-bar {
    transition: all linear 0.1s;
    width: 200px;
    height: 100%;
    flex-shrink: 0;
    // box-shadow:5px 0 10px -5px rgba(0,0,0,1);
    border: 1px solid #eee;
    .slide-title {
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      h3 {
        margin: 0;
      }
      .img-folding {
        width: 24px;
        height: 24px;
      }
    }
  }
  .slide-bar /deep/ .el-menu {
    height: 100%;
  }
  // .slide-bar /deep/ i, .slide-bar /deep/  span{
  //     position: relative;
  //     left: -35px;
  // }
  // .slide-bar /deep/ .el-submenu__icon-arrow{
  //     position: absolute;
  //     left: 160px;
  // }
  .slide-bar /deep/ .el-menu {
    border: none;
  }
  .content {
    background-color: #fff;
    width: calc(100vw - 64px);
    // height: 100%;
    // overflow: hidden;
    .content-top {
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .top-desc {
        margin-left: 40px;
      }
      .avator-outer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 40px;
      }
      .el-icon-message-solid {
        margin-right: 30px;
        font-size: 20px;
      }
      .avator-box {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #eee;
        overflow: hidden;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .content-mid {
      background-color: #eff2f7;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      height: 64px;
      h3 {
        margin: 0;
        margin-left: 40px;
      }
      .bread-box {
        margin-right: 40px;
      }
    }
    .content-inner {
      height: calc(100% - 56px);
      overflow: auto;
    }
  }
}
.slide {
  width: 64px !important;
}
.foldContent {
  width: calc(100vw - 200px) !important;
}
.toolTip {
  li {
    cursor: pointer;
  }
}
.content-top /deep/ .el-tabs__header {
  margin: 0;
}
.content-top /deep/ .el-tabs {
  margin-left: 40px;
  align-self: flex-end;
}
.data-center {
  margin-left: 10px;
  cursor: pointer;
}
</style>
