<template>
  <div id="app">
    <keep-alive :include="['pcContent']">
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    console.log('加载1');
    
    // if (this._isMobile()) {
    //   this.$router.replace('/m_login');
    // } else {
    //   this.$router.replace('/pc_login');
    // }
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  }
}
</script>

<style lang="scss">
body{
  padding:0;
  margin:0;
  height:100vh;
overflow: hidden;

}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
input{
  border: none;
  outline: none;
  &:focus{
    border: none;
  outline: none;
  }
}
ul{
  padding: 0;
  margin: 0;
}
li{
  list-style: none;
  margin: 0;
  padding: 0;
}
.el-tabs__item.is-active {
    background-color: #f9fafc;
}
p{
  padding: 0;
  margin: 0;
}
</style>
