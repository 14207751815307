<template>
  <div class="container">
    <div class="select-box">
      <div class="select-top">
        <div class="select-date-box">
          <div class="select-item date-box">
            <span class="select-desc">开始时间:</span>
            <el-date-picker
              v-model="where.kssj"
              align="right"
              size="mini"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="select-item date-box">
            <span class="select-desc">结束时间:</span>
            <el-date-picker
              v-model="where.jssj"
              align="right"
              size="mini"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="day-num-box">
            <el-select
              v-model="dayValue"
              size="mini"
              placeholder="请选择"
              @change="changeDayNum"
            >
              <el-option
                v-for="item in BaoBiaoCom.dayNum"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <h3>{{BiaoTi}}</h3>
        <div class="select-indicators-box">
          <div class="select-item">
            <span class="select-desc">平均销量:</span>
            <!-- <input type="number" /> -->
            <input
              class="indicators-content"
              type="number"
              v-model="FanHuiD.xiaoliang"
              placeholder="请输入销量指标"
              @blur="changeXiaoLiang"
            />
          </div>
          <div class="select-item">
            <span class="select-desc">平均毛利:</span>
            <!-- <input type="number" /> -->
            <input
              class="indicators-content"
              type="number"
              v-model="FanHuiD.maoli"
              @blur="changeMaoLi"
            />
          </div>
        </div>
      </div>
      <div class="show-content" v-show="showRequestFlag">
        <div class="select-content">
          <div class="select-item">
            <span class="select-desc">总公司:</span>
            <el-select
              v-model="where.CompanyValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择总公司"
            >
              <el-option
                v-for="item in KeHuData.ZongGongSiD"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span class="select-desc">大区:</span>
            <el-select
              v-model="where.DQdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择大区"
            >
              <el-option
                v-for="item in KeHuData.DQdtList"
                :key="item.khbh"
                :label="item.dwjc"
                :value="item.khbh"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span class="select-desc">业务员:</span>
            <el-select
              v-model="where.YWdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择业务员"
            >
              <el-option
                v-for="item in KeHuData.YWdtList"
                :key="item.ywybh"
                :label="item.xm"
                :value="item.ywybh"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span class="select-desc">区域:</span>
            <el-select
              v-model="where.QYdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择区域"
            >
              <el-option
                v-for="item in KeHuData.QYdtList"
                :key="item.ssqy"
                :label="item.ssqy"
                :value="item.ssqy"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="select-content select-content2">
          <div class="select-item">
            <span class="select-desc">分类:</span>
            <el-select
              v-model="where.FLdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择分类"
            >
              <el-option
                v-for="item in ChanPinData.FLdtList"
                :key="item.lbdm"
                :label="item.lbmc"
                :value="item.lbdm"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span class="select-desc">厂家:</span>
            <el-select
              v-model="where.GYSdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择厂家"
            >
              <el-option
                v-for="item in ChanPinData.GYSdtList"
                :key="item.gysbh"
                :label="item.dwjc"
                :value="item.gysbh"
              >
              </el-option>
            </el-select>
          </div>
          <div class="select-item">
            <span class="select-desc">品牌:</span>
            <el-select
              v-model="where.PPdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择品牌"
            >
              <el-option
                v-for="item in ChanPinData.PPdtList"
                :key="item.wwwppbh"
                :label="item.wwwppmc"
                :value="item.wwwppbh"
              >
              </el-option>
            </el-select>
            <el-checkbox
              v-model="where.isZengPin"
              size="mini"
              label="剔除赠品"
              border
            ></el-checkbox>
          </div>
        </div>
        <div class="select-content">
          <div class="select-item" style="width: 50%">
            <span class="select-desc">门店:</span>
            <input
              type="text"
              class="mendian-input"
              v-show="where.MenDianIsT"
              v-model="where.MenDianT"
              placeholder="门店关键字"
            />
            <el-select
              v-show="!where.MenDianIsT"
              v-model="where.MCdtValue"
              size="mini"
              multiple
              filterable
              default-first-option
              placeholder="请选择门店"
            >
              <el-option
                v-for="item in KeHuData.MCdtList"
                :key="item.khbh"
                :label="item.dwjc"
                :value="item.khbh"
              >
              </el-option>
            </el-select>
            <div class="check-box">
              <el-checkbox
                v-model="where.MenDianIsT"
                size="mini"
                label="模糊"
                border
              ></el-checkbox>
            </div>
          </div>
          <div class="select-item" style="width: 50%">
            <span class="select-desc">产品:</span>
            <input
              type="text"
              class="product-input"
              v-show="where.ChanPinIsT"
              v-model="where.ChanPinT"
              placeholder="产品关键字"
            />
            <el-select
              v-show="!where.ChanPinIsT"
              v-model="where.CPdtValue"
              size="mini"
              filterable
              multiple
              default-first-option
              placeholder="请选择产品"
            >
              <el-option
                v-for="item in ChanPinData.CPdtList"
                :key="item.cpbh"
                :label="item.cpmc"
                :value="item.cpbh"
              >
              </el-option>
            </el-select>

            <!-- <div class="check-box"> -->
            <el-checkbox
              v-model="where.ChanPinIsT"
              size="mini"
              label="模糊"
              border
            ></el-checkbox>

            <!-- </div> -->
          </div>
          <el-button type="primary" size="mini" @click="toQuery"
            >查询数据</el-button
          >
          <el-button type="info" size="mini" @click="exportNewList"
            >导出</el-button
          >
        </div>
        <!-- <div class="btns">
          <el-button type="primary" @click="toQuery">查询数据</el-button>
        </div> -->
      </div>

      <!-- <div
        v-show="showRequestFlag"
        class="operate-box"
        @click="showRequestFlag = false"
      >
        <i class="el-icon-d-arrow-left"></i>
        收起查询面板
        <i class="el-icon-d-arrow-left"></i>
      </div> -->
      <el-alert
        style="margin: 0px 0px 0px 30px; width: 95%; padding: 0px"
        type="success"
        :closable="false"
        :title="
          '共查出[' +
          ZongBiao.length +
          ']条数据,总销量：￥' +
          FanHuiD.Zxiaoliang +
          '元。总毛利：' +
          FanHuiD.Zmaoli +
          '元，毛利率：' +
          FanHuiD.ZmaoliLv +
          '%'
        "
      >
      </el-alert>
      <div
        v-show="!showRequestFlag"
        class="operate-box"
        @click="showRequestFlag = true"
      >
        <!-- <i class="el-icon-d-arrow-right"></i>
        展开查询面板
        <i class="el-icon-d-arrow-right"></i> -->
        展开。。。
      </div>
    </div>

    <div class="table-box">
      <div class="table-left"  v-show="isAllTable||tableScreenIndex==1" :class="tableScreenIndex==1?'screen-table':''">
       
        <div class="table-title" style="background-color: #e6a23c">
          <span>{{ FenBiao1.ShuoMing }}</span>
          <img v-if="FenBiao1.ShuoMing" class="full-img" src="@/assets/images/fullScreen.png" @click="changeScreenIndex(1)" />
        </div>

        <avue-crud
          ref="table1"
          :data="FenBiao1.data"
          :option="BaoBiaoCom.listCPBiao"
          :table-loading="tableLTloading"
          @row-click="rowClick"
        >
          <template slot="PuShiShu" slot-scope="scope">
            <el-tag size="medium">{{ scope.row.PuShiShu }}</el-tag>
          </template>
          <template slot="cpmc" slot-scope="scope">
            <p class="content-detail">{{ scope.row.cpmc }}</p>
            <div class="content-tag">
              <el-tag
                type="success"
                size="mini"
                v-for="bq in GetBiaoQian(scope.row.BiaoQian)"
                :key="bq"
                >{{ bq }}</el-tag
              >
              <i
                class="el-icon-plus"
                v-if="BiaoKeHu"
                @click="DaBiaoQian(scope.row)"
              ></i>
            </div>
          </template>
        </avue-crud>
      </div>

      <div class="table-right"  v-show="isAllTable||tableScreenIndex==2" :class="tableScreenIndex==2?'screen-table':''">
        <div class="table-title" style="background-color: #67c23a">
          <span>{{ FenBiao2.ShuoMing }}</span>
          <img v-if="FenBiao1.ShuoMing" class="full-img" src="@/assets/images/fullScreen.png" @click="changeScreenIndex(2)" />
        </div>
        <avue-crud
          ref="table2"
          :data="FenBiao2.data"
          :option="BaoBiaoCom.listCPBiao"
          :table-loading="tableLTloading"
          @row-click="rowClick"
        >
          <template slot="PuShiShu" slot-scope="scope">
            <el-tag size="medium">{{ scope.row.PuShiShu }}</el-tag>
          </template>
          <template slot="cpmc" slot-scope="scope">
            <p class="content-detail">{{ scope.row.cpmc }}</p>
            <div class="content-tag">
              <el-tag
                type="success"
                size="mini"
                v-for="bq in GetBiaoQian(scope.row.BiaoQian)"
                :key="bq"
                >{{ bq }}</el-tag
              >
              <i
                class="el-icon-plus"
                v-if="BiaoKeHu"
                @click="DaBiaoQian(scope.row)"
              ></i>
            </div>
          </template>
        </avue-crud>
      </div>
    </div>
    <div class="table-box">
      <div class="table-left" v-show="isAllTable||tableScreenIndex==3" :class="tableScreenIndex==3?'screen-table':''">
        <div class="table-title" style="background-color: #f56c6c">
          <span>{{ FenBiao3.ShuoMing }}</span>
          <img v-if="FenBiao1.ShuoMing" class="full-img" src="@/assets/images/fullScreen.png" @click="changeScreenIndex(3)" />
        </div>
        <avue-crud
          ref="table3"
          :data="FenBiao3.data"
          :option="BaoBiaoCom.listCPBiao"
          :table-loading="tableLTloading"
          @row-click="rowClick"
        >
          <template slot="PuShiShu" slot-scope="scope">
            <el-tag size="medium">{{ scope.row.PuShiShu }}</el-tag>
          </template>
          <template slot="cpmc" slot-scope="scope">
            <p class="content-detail">{{ scope.row.cpmc }}</p>
            <div class="content-tag">
              <el-tag
                type="success"
                size="mini"
                v-for="bq in GetBiaoQian(scope.row.BiaoQian)"
                :key="bq"
                >{{ bq }}</el-tag
              >
              <i
                class="el-icon-plus"
                v-if="BiaoKeHu"
                @click="DaBiaoQian(scope.row)"
              ></i>
            </div>
          </template>
        </avue-crud>
      </div>
      <div class="table-right"  v-show="isAllTable||tableScreenIndex==4" :class="tableScreenIndex==4?'screen-table':''">
        <div class="table-title" style="background-color: #e6a23c">
          <span>{{ FenBiao4.ShuoMing }}</span>
          <img v-if="FenBiao1.ShuoMing" class="full-img" src="@/assets/images/fullScreen.png" @click="changeScreenIndex(4)" />
        </div>
        <avue-crud
          ref="table4"
          :data="FenBiao4.data"
          :option="BaoBiaoCom.listCPBiao"
          :table-loading="tableLTloading"
          @row-click="rowClick"
        >
          <template slot="PuShiShu" slot-scope="scope">
            <el-tag size="medium">{{ scope.row.PuShiShu }}</el-tag>
          </template>
          <template slot="cpmc" slot-scope="scope">
            <p class="content-detail">{{ scope.row.cpmc }}</p>
            <div class="content-tag">
              <el-tag
                type="success"
                size="mini"
                v-for="bq in GetBiaoQian(scope.row.BiaoQian)"
                :key="bq"
                >{{ bq }}</el-tag
              >
              <i
                class="el-icon-plus"
                v-if="BiaoKeHu"
                @click="DaBiaoQian(scope.row)"
              ></i>
            </div>
          </template>
        </avue-crud>
      </div>
    </div>

    <div class="table-box table-user">
      <div class="block" v-for="ck in ckdtList" :key="ck.id">
        <el-avatar size="small" :src="ck.headIcon" class="ava"></el-avatar>
        <div class="name">{{ ck.username }}</div>
        <span class="time">{{ ck.clicksj }}</span>
      </div>
    </div>
    <div>
      <el-dialog :title="FenJien.name" :visible.sync="FenJien.dialog">
 <avue-crud
          :data="FenJien.FenJieData"
          :option="BaoBiaoCom.listCPFenJieBiao"
        >
            <template slot="dwjc" slot-scope="scope">
              <p class="content-detail">{{ scope.row.dwjc }}</p>
              <div class="content-tag">
                <el-tag
                  type="success"
                  size="mini"
                  v-for="bq in GetBiaoQian(scope.row.BiaoQian)"
                  :key="bq"
                  >{{ bq }}</el-tag
                >
              </div>
            </template>
        </avue-crud>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="DaoChuFenJie">导 出</el-button>
          <el-button @click="FenJien.dialog = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

    <div class="select-dialog-outer">
      <el-dialog title="标签选择" :visible.sync="BiaoQian.dialog">
        {{ BiaoQian.name }}
        <el-checkbox-group v-model="BiaoQian.XuanZhongBiaoQian">
          <el-checkbox
            :label="item"
            v-for="(item, index) in BaoBiaoCom.BiaoQianChanPinXuanXiang"
            :key="index"
          ></el-checkbox>
        </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="QueRenBiaoQian">确 认</el-button>
          <el-button @click="BiaoQian.dialog = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import * as moment from "moment";
import XLSX from "xlsx";
import BaoBiao from "@/common/BaoBiao.js";
export default {
  data() {
    return {
      isAllTable:true, //是否显示全部表格
      tableScreenIndex:0, //当前全屏的表格
      NBWanStoken: "",
      ERPShuJuKu: "",
      BiaoTi:'万商软件',
      BaoBiaoCom: [], //报表常用表量
      FanHuiD: {
        Zxiaoliang: 0,
        Zmaoli: 0,
        ZmaoliLv: 0,
        xiaoliang: 0,
        maoli: 0,
        xiaoliangB: 0,
        maoliB: 0,
      }, //返回的数据
      ChanPinData: { CPdtList: [], FLdtList: [], GYSdtList: [], PPdtList: [] }, //产品下拉
      ckdtList: [], //查看列表
      KeHuData: {
        ZongGongSiD: [],
        MCdtList: [],
        DQdtList: [],
        YWdtList: [],
        QYdtList: [],
      }, //客户下拉
      where: {
        kssj: "",
        jssj: "",
        CompanyValue: "",
        isZengPin: false,
        DQdtValue: "",
        CPdtValue: "",
        FLdtValue: "",
        GYSdtValue: "",
        PPdtValue: "",
        MCdtValue: "",
        YWdtValue: "",
        QYdtValue: "",
        MenDianT: "",
        ChanPinT: "",
        MenDianIsT: false,
        ChanPinIsT: false,
      }, //查询条件
      whereT: {}, //查询文本
      dayValue: "30",
      BiaoQian: { dialog: false, name: "", id: "", XuanZhongBiaoQian: [] }, //标签
      FenJien: {
        dialog: false,
        name: "",
        id: "",
        FenJietabs: "XiaoShouMenDian",
        FenJieData: [],
      }, //分解
      tableLTloading: false, //加载中
      BiaoKeHu: false,
      showRequestFlag: true,
      ZongBiao: [], //主表
      FenBiao1: { name: "低销售、高毛利率", data: [], ShuoMing: "" },
      FenBiao2: { name: "高销售、高毛利率", data: [], ShuoMing: "" },
      FenBiao3: { name: "低销售、低毛利率", data: [], ShuoMing: "" },
      FenBiao4: { name: "高销售、低毛利率", data: [], ShuoMing: "" },

    };
  },
  mounted() {
    this.NBWanStoken = this.storage.get("userToken");
    if (!this.NBWanStoken) {
      this.$router.push("/pcLogin");
    } else {
      this.BaoBiaoCom = BaoBiao[0];
      this.where.jssj = moment(new Date()).format("YYYY-MM-DD");
      if (this.storage.get("BiaoKeHu") == "1") {
        //标产品的权限
        this.BiaoKeHu = true;
      }
      //公司处理
      this.KeHuData.ZongGongSiD = this.BaoBiaoCom.companyListZS;
      this.ERPShuJuKu = "ZSYouShangERP";
      this.BiaoTi='产品四象限分析表(万豪)';
      this.changeDayNum(30);
      this.getChanPin();
      this.getMenDian();
    }
  },
  methods: {
    //表格是否全屏
    changeScreenIndex(index){
      let tableitem = 'table'+index
      if(this.tableScreenIndex==0){

        this.tableScreenIndex = index;
        this.$refs[tableitem].tableHeight = 560;
        this.isAllTable = false;
      }else{
        this.tableScreenIndex = 0;
        this.$refs[tableitem].tableHeight = 280;
        this.isAllTable = true;
      }
    },
    //展示标签选择弹窗
    DaBiaoQian(row) {
      this.BiaoQian.XuanZhongBiaoQian = this.GetBiaoQian(row.BiaoQian);
      this.BiaoQian.name = row.cpmc;
      this.BiaoQian.id = row.cpbh;
      this.BiaoQian.dialog = true;
    },
    //标签选择确认
    QueRenBiaoQian() {
      //console.log(this.BiaoQian.XuanZhongBiaoQian);
      this.axios
        .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
          params: {
            method: "BiaoQianChanPin",
            NBWanStoken: this.NBWanStoken,
            cpbh: this.BiaoQian.id,
            BiaoQian: this.BiaoQian.XuanZhongBiaoQian.join("|"),
            ERPShuJuKu: this.ERPShuJuKu,
          },
        })
        .then((res) => {
          if (res.data.Success) {
            this.BiaoQian.dialog = false;
          } else {
            alert(res.data.Message);
          }
        });
    },
    GetBiaoQian(bpT) {
      if (bpT) {
        var bps = [];
        bps = bpT.split("|");
        return bps;
      } else {
        return [];
      }
    },
    // 销量失去焦点
    changeXiaoLiang(e) {
      if (e.target.value != this.FanHuiD.xiaoliangB) {
        this.FanHuiD.xiaoliangB = e.target.value;
        if (this.ZongBiao.length > 0) {
          this.FenBiaoXianShi();
        }
      }
    },
    // 毛利失去焦点
    changeMaoLi(e) {
      if (e.target.value != this.FanHuiD.maoliB) {
        this.FanHuiD.maoliB = e.target.value;
        if (this.ZongBiao.length > 0) {
          this.FenBiaoXianShi();
        }
      }
    },
    //点击表格行触发事件
    //row 包含该行中所有的数据
    rowClick(row, column, event) {
      switch (column.property) {
        case "PuShiShu":
          this.whereT.cpbh = row.cpbh;
          this.FenJien.id = row.cpbh;
          this.FenJien.name = row.cpmc;
          this.FenJien.FenJietabs = "XiaoShouMenDian";
          this.whereT.method = "PuShiMenDian";
          this.axios
            .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
              params: this.whereT,
            })
            .then((res) => {
              if (res.data.Success) {
                this.FenJien.FenJieData = JSON.parse(res.data.Data);
                this.FenJien.dialog = true;
              } else {
                alert(res.data.Message);
              }
            });
          // console.log(row.cpbh);

          break;
      }
      //console.log(row,column,event);
    },

    //弹出窗的导出
    DaoChuFenJie() {
      //基础设置
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var tabHandle = [];
       this.BaoBiaoCom.listKHFenJieBiao.column.forEach((item) => {
        tabHandle.push(item.label);
      });
      let tableData2 = []; //定义一个表
     tableData2.push(tabHandle);
      this.FenJien.FenJieData.forEach((item, index) => {
        //数据表
        let rowData = [];
        //导出内容的字段
        rowData = [item.dwjc, item.cs, item.XSje, item.XSml];
        tableData2.push(rowData);
      });
      let workSheet2 = XLSX.utils.json_to_sheet(tableData2, xlsxParam);
      let bookNew = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(bookNew, workSheet2, "铺市情况");
      let name = this.FenJien.name + "铺市.xlsx"; //表格名称
      XLSX.writeFile(bookNew, name); // 保存的文件名
    },
    exportNewList() {
      //基础设置
      var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式
      var tabHandle = []; //导出表头
      this.BaoBiaoCom.listCPBiao.column.forEach((item) => {
        tabHandle.push(item.label);
      });

      let tableData2 = [];
      let tableData3 = [];
      let tableData4 = [];
      let tableData5 = [];

      tableData2.push([this.FenBiao1.ShuoMing]);
      tableData3.push([this.FenBiao2.ShuoMing]);
      tableData4.push([this.FenBiao3.ShuoMing]);
      tableData5.push([this.FenBiao4.ShuoMing]);

      tableData2.push(tabHandle);
      tableData3.push(tabHandle);
      tableData4.push(tabHandle);
      tableData5.push(tabHandle);

      this.FenBiao1.data.forEach((item, index) => {
        let rowData = [];
        //导出内容的字段
        rowData = [
          item.cpmc,
          item.PuShiShu,
          item.XSje,
          item.XSml,
          item.mll,
          item.thl,
        ];
        tableData2.push(rowData);
      });

      this.FenBiao2.data.forEach((item, index) => {
        let rowData = [];
        //导出内容的字段
        rowData = [
          item.cpmc,
          item.PuShiShu,
          item.XSje,
          item.XSml,
          item.mll,
          item.thl,
        ];
        tableData3.push(rowData);
      });

      this.FenBiao3.data.forEach((item, index) => {
        let rowData = [];
        //导出内容的字段
        rowData = [
          item.cpmc,
          item.PuShiShu,
          item.XSje,
          item.XSml,
          item.mll,
          item.thl,
        ];
        tableData4.push(rowData);
      });
      this.FenBiao4.data.forEach((item, index) => {
        let rowData = [];
        //导出内容的字段
        rowData = [
          item.cpmc,
          item.PuShiShu,
          item.XSje,
          item.XSml,
          item.mll,
          item.thl,
        ];
        tableData5.push(rowData);
      });

      var sm =
        "以上数据档期[" +
        this.where.kssj +
        "]至[" +
        this.where.jssj +
        "]以及选择的对应条件出库数据，本数据只做参考。";
      tableData2.push([sm]);
      tableData3.push([sm]);
      tableData4.push([sm]);
      tableData5.push([sm]);
      // console.log(tableData)
      let workSheet2 = XLSX.utils.json_to_sheet(tableData2, xlsxParam);
      let workSheet3 = XLSX.utils.json_to_sheet(tableData3, xlsxParam);
      let workSheet4 = XLSX.utils.json_to_sheet(tableData4, xlsxParam);
      let workSheet5 = XLSX.utils.json_to_sheet(tableData5, xlsxParam);
      let bookNew = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(bookNew, workSheet2, this.FenBiao1.name);
      XLSX.utils.book_append_sheet(bookNew, workSheet3, this.FenBiao2.name);
      XLSX.utils.book_append_sheet(bookNew, workSheet4, this.FenBiao3.name);
      XLSX.utils.book_append_sheet(bookNew, workSheet5, this.FenBiao4.name);
      let name = "产品四象限分析表.xlsx"; //表格名称
      XLSX.writeFile(bookNew, name); // 保存的文件名
    },

    //时间间隔更改
    changeDayNum(e) {
      // console.log(111)
      //console.log(e)
      if (this.where.jssj) {
        var nowDate = moment(this.where.jssj);
        var dateFrom = nowDate.subtract(e, "d").format("YYYY-MM-DD");
        this.where.kssj = dateFrom;
      }
    },
    handleClick(tab, event) {
      //分解切换
      console.log(tab, event);
    },
    getChanPin() {
      this.axios
        .get(
          `${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx?method=XiaZaiSuoYouChanPin&NBWanStoken=` +
            this.NBWanStoken +
            "&ERPShuJuKu=" +
            this.ERPShuJuKu
        )
        .then((res) => {
          if (res.status == 200) {
            this.ChanPinData.CPdtList = res.data.CPdt;
            this.ChanPinData.FLdtList = res.data.FLdt;
            this.ChanPinData.GYSdtList = res.data.GYSdt;
            this.ChanPinData.PPdtList = res.data.PPdt;
            this.ckdtList = res.data.ckdt;
          }
        });
    },
    getMenDian() {
      this.axios
        .get(
          `${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx?method=XiaZaiSuoYouMenDian&NBWanStoken=` +
            this.NBWanStoken +
            "&ERPShuJuKu=" +
            this.ERPShuJuKu
        )
        .then((res) => {
          if (res.status == 200) {
            this.KeHuData.MCdtList = res.data.MCdt;
            this.KeHuData.DQdtList = res.data.DQdt;
            this.KeHuData.YWdtList = res.data.YWdt;
            this.KeHuData.QYdtList = res.data.QYdt;
          }
        });
    },
    // 错误消息提示
    openError(errMessage) {
      this.$message({
        showClose: true,
        message: errMessage,
        type: "warning",
      });
    },
    //查询
    toQuery() {
      // this.showRequestFlag = false;
      let YeWuYuan = "";
      for (var i = 0; i < this.where.YWdtValue.length; i++) {
        YeWuYuan = YeWuYuan + "'" + this.where.YWdtValue[i] + "'";
        if (i + 1 != this.where.YWdtValue.length) {
          YeWuYuan = YeWuYuan + ",";
        }
      }
      let QuYu = ""; //区域
      for (var i = 0; i < this.where.QYdtValue.length; i++) {
        QuYu = QuYu + "'" + this.where.QYdtValue[i] + "'";
        if (i + 1 != this.where.QYdtValue.length) {
          QuYu = QuYu + ",";
        }
      }
      let PinPai = ""; //品牌
      for (var i = 0; i < this.where.PPdtValue.length; i++) {
        PinPai = PinPai + "'" + this.where.PPdtValue[i] + "'";
        if (i + 1 != this.where.PPdtValue.length) {
          PinPai = PinPai + ",";
        }
      }
      this.ZongBiao = [];
      this.FenBiao1 = { name: "低销售、高毛利率", data: [], ShuoMing: "" };
      this.FenBiao2 = { name: "高销售、高毛利率", data: [], ShuoMing: "" };
      this.FenBiao3 = { name: "低销售、低毛利率", data: [], ShuoMing: "" };
      this.FenBiao4 = { name: "高销售、低毛利率", data: [], ShuoMing: "" };
      this.tableLTloading = true;
      if (!this.where.kssj) {
        this.openError("未选择开始时间");
        return;
      }
      if (!this.where.jssj) {
        this.openError("未选择结束时间");
        return;
      }

      if (this.where.MenDianIsT) {
        if (!this.where.MenDianT) {
          this.openError("未填写门店");
          return;
        }
      }
      if (this.where.ChanPinIsT) {
        if (!this.where.ChanPinT) {
          this.openError("未填写产品");
          return;
        }
      }
      this.whereT = {
        method: "SiXiangXianChanPinBaoBiao",
        kssj: this.where.kssj,
        jssj: this.where.jssj,
        isZengPin: this.where.isZengPin ? "1" : "0",
        ZongGongSi: this.where.CompanyValue.join(","),
        DaQu: this.where.DQdtValue.join(","),
        YeWuYuan: YeWuYuan,
        QuYu: QuYu,
        MenDian: this.where.MCdtValue.join(","),
        MenDianT: this.MenDianT,
        MenDianIsT: this.where.MenDianIsT ? "1" : "0",
        FenLei: this.where.FLdtValue.join(","),
        ChangJia: this.where.GYSdtValue.join(","),
        PinPai: PinPai,
        ChanPin: this.where.CPdtValue.join(","),
        ChanPinT: this.where.ChanPinT,
        ChanPinIsT: this.where.ChanPinIsT ? "1" : "0",
        NBWanStoken: this.NBWanStoken,
        ERPShuJuKu: this.ERPShuJuKu,
      };

      this.axios
        .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
          params: this.whereT,
        })
        .then((res) => {
          this.tableLTloading = false;
          if (res.data.Success) {
            this.FanHuiD.xiaoliang = parseFloat(res.data.CanShu1);
            this.FanHuiD.maoli = parseFloat(res.data.CanShu2);
            this.FanHuiD.Zxiaoliang = parseFloat(res.data.CanShu3);
            this.FanHuiD.Zmaoli = parseFloat(res.data.CanShu5);
            this.FanHuiD.ZmaoliLv = parseFloat(res.data.CanShu6);
            this.FanHuiD.xiaoliangB = parseFloat(res.data.CanShu1);
            this.FanHuiD.maoliB = parseFloat(res.data.CanShu2);
            this.ZongBiao = JSON.parse(res.data.Data);
            //得到总表，然后根据平均值取四个表
            this.FenBiaoXianShi();
          } else {
            alert(res.data.Message);
          }
        });
    },
    FenBiaoXianShi() {
      //分表显示
      
      this.FenBiao1.data=[];
      this.ZongBiao.forEach((item, index, array) => {
        if (
          item.XSje < this.FanHuiD.xiaoliangB &&
          item.mll >= this.FanHuiD.maoliB
        ) {
          //销量<平均销量   毛利>=平均毛利
          this.FenBiao1.data.push(item);
        }
        this.FenBiao1.ShuoMing =
          "销量<" +
          this.FanHuiD.xiaoliangB +
          "【低】、毛利率>=" +
          this.FanHuiD.maoliB +
          "【高】待优化【共" +
          this.FenBiao1.data.length +
          "条,占" +
          ((this.FenBiao1.data.length / this.ZongBiao.length) * 100).toFixed(
            2
          ) +
          "%】";
      });
      
      this.FenBiao2.data=[];
      this.ZongBiao.forEach((item, index, array) => {
        if (
          item.XSje >= this.FanHuiD.xiaoliang &&
          item.mll >= this.FanHuiD.maoliB
        ) {
          //销量>=平均销量   毛利>=平均毛利
          this.FenBiao2.data.push(item);
        }
        this.FenBiao2.ShuoMing =
          "销量>=" +
          this.FanHuiD.xiaoliangB +
          "【高】、毛利率>=" +
          this.FanHuiD.maoliB +
          "【高】继续保持【共" +
          this.FenBiao2.data.length +
          "条,占" +
          ((this.FenBiao2.data.length / this.ZongBiao.length) * 100).toFixed(
            2
          ) +
          "%】";
      });
      
      this.FenBiao3.data=[];
      this.ZongBiao.forEach((item, index, array) => {
        if (
          item.XSje < this.FanHuiD.xiaoliangB &&
          item.mll < this.FanHuiD.maoliB
        ) {
          //销量<平均销量   毛利<平均毛利
          this.FenBiao3.data.push(item);
        }
        this.FenBiao3.ShuoMing =
          "销量<" +
          this.FanHuiD.xiaoliangB +
          "【低】、毛利率<" +
          this.FanHuiD.maoliB +
          "【低】需整理【共" +
          this.FenBiao3.data.length +
          "条,占" +
          ((this.FenBiao3.data.length / this.ZongBiao.length) * 100).toFixed(
            2
          ) +
          "%】";
      });
      
      this.FenBiao4.data=[];
      this.ZongBiao.forEach((item, index, array) => {
        if (
          item.XSje >= this.FanHuiD.xiaoliangB &&
          item.mll < this.FanHuiD.maoliB
        ) {
          //销量>=平均销量   毛利<平均毛利
          this.FenBiao4.data.push(item);
        }
        this.FenBiao4.ShuoMing =
          "销量>=" +
          this.FanHuiD.xiaoliangB +
          "【高】、毛利率<" +
          this.FanHuiD.maoliB +
          "【低】待优化【共" +
          this.FenBiao4.data.length +
          "条,占" +
          ((this.FenBiao4.data.length / this.ZongBiao.length) * 100).toFixed(
            2
          ) +
          "%】";
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  // width: 100%;
  // background: #fff;
  // padding: 40px;
  // background-color: #409eff; /* 浏览器不支持时显示 */
  // background-image: linear-gradient(#409eff,#409eff,#fff);
  // min-width: 1200px;

  background: #f9fafc;
  .select-box {
    display: flex;
    flex-direction: column;
    // background:#eee;
    .select-top,
    .select-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 5px;
      .select-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 200px;
      }
    }
    .select-top {
      // background-color: #409eff;

      color: #333;
      justify-content: space-between;

      .select-date-box,
      .select-indicators-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        //width: 495px;
      }
      .select-indicators-box {
        justify-content: flex-end;
      }
    }
    .select-content {
      margin: 5px 0;
      .select-item {
        width: 30%;
        &:first-child {
          width: 18%;
        }
      }
    }
    .select-content2 {
      .select-item {
        width: 40%;
        &:first-child {
          width: 20%;
        }
        .check-box {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }
  }
  .table-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    // padding: 0 40px;
    margin: 0px 0;
    .table-left,
    .table-right {
      width: 49%;
      // max-width: 862px;
      // margin: 40px;
    }
    .table-left {
      margin-left: 5px;
    }
    .table-right {
      margin-right: 5px;
    }
  }
}
.select-desc {
  display: block;
  width: 80px;
  text-align: right;
  flex-shrink: 0;
}
.indicators-content {
  background-color: #fff;
  width: 50px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
  padding-left: 6px;
}

.day-num-box /deep/ .el-input {
  width: 90px;
}
.day-num-box {
  margin-left: 20px;
}
.date-box /deep/ .el-date-editor.el-input {
  width: 100%;
  // min-width: 120px;
}
.date-box /deep/ .el-input--suffix .el-input__inner {
  padding-right: 0;
}
// .check-box {
//   margin-left: 20px;
// }
.check-box /deep/ .el-checkbox.is-bordered {
  margin-right: 0;
}
.select-content /deep/ .el-checkbox {
  margin-left: 5px;
}
.container /deep/ .el-select__tags {
  flex-wrap: nowrap;
  overflow: hidden;
}
.operate-box {
  padding: 10px;
  color: #fff;
  // color: #333;
  // background-image: linear-gradient(
  //   to bottom,
  //   rgba(240, 242, 245, 0.4),
  //   rgba(202, 205, 207, 0.4),
  //   rgba(240, 242, 245, 0.4)
  // );
  background: #66b1ff;
  cursor: pointer;
}
.el-icon-d-arrow-left,
.el-icon-d-arrow-right {
  transform: rotate(90deg);
}
.show-content {
  // background: rgba(255,255,255,.4);
  background: #f9fafc;
}
.btns {
  margin-bottom: 20px;
}
.product-input,
.mendian-input {
  width: 100%;
  height: 38px;
  border: 1px solid #eee;
  border-radius: 6px;
  padding-left: 10px;
  &::placeholder {
    color: #c8c9cc;
  }
}
.table-title {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  border-bottom: 1px solid #eee;
  .table-download {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .full-img{
    width: 20px;
    height:20px;
    margin: 0 10px;
    cursor:pointer;
  }
}
.container /deep/ .el-select {
  width: 100%;
}
.product-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 40% !important;
}
.select-content /deep/ .el-button {
  margin-left: 5px;
}
.table-box /deep/ .cell {
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ava,
    .name,
    .time {
      margin-top: 3px;
    }
    .name {
      font-size: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .time {
      font-size: 8px;
      padding: 3px;
    }
  }
}

.Zxiaoliang {
  font-size: x-small;
  color: #747679;
}

.table-box /deep/ .cell .content-detail {
  margin-top: 9px;
}
.table-box /deep/ .cell .content-tag {
  position: absolute;
  top: 0px;
  right: 0px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.table-box /deep/ .cell .content-tag .el-tag {
  margin-right: 3px;
  font-size: 8px;
}
.select-dialog-outer /deep/ .el-dialog {
  width: 360px;
}
.select-dialog-outer /deep/ .el-dialog .dialog-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.select-dialog-outer /deep/ .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 10px;
}
.select-dialog-outer /deep/ .el-dialog__body {
  padding: 10px 20px;
}
.el-table__footer-wrapper {
  margin-top: 0px;
}
.screen-table{
  width:100%!important;
}
</style>
