<template>
  <div class="container">
    <div class="login-box">
      <h3>登录系统</h3>
      <div class="login-content">
        <form action="">
          <div class="login-item">
            <!-- <label for="name">
              <i>名字</i>
            </label> -->
            <input
              type="text"
              id="tel"
              placeholder="手机号"
              v-model="tel"
              placeholder-class="place"
              class="tel-input"
            />
          </div>
          <div class="login-item login-code">
            <!-- <label for="name">
              <i>名字</i>
            </label> -->
            <input
              type="text"
              id="code"
              v-model="code"
              placeholder="验证码"
              placeholder-class="place"
              class="code-input"
            />
            <!-- <el-button type="primary" :loading="getCodeFlag" v-show="sendFlag"
              >获取中</el-button
            > -->
            <div class="code-btn" @click="getCode" v-show="!sendFlag">
              {{ buttonMessage }}
            </div>
          </div>
          <div class="login-button" @click="toIndex">登录</div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tel: "",
      code: "",
      getCodeFlag: true,
      sendFlag: false,
      buttonMessage: "获取短信验码",
      openid:''
    };
  },
  mounted(options){
    this.NBWanStoken = this.storage.get('userToken');
    if(this.NBWanStoken){
      if(this.$isMobile()){
        this.$router.push("/mDataCenter");
      }else{
        this.$router.push("/");
      }

    }
  },
  methods: {
    async toIndex() {
      if (!this.code) {
        this.$message({
          message: "请先输入验证码",
          type: "warning",
        });
        return;
      }
      let flag = await this.checktel(this.tel);
      if(flag){
        this.axios
        .get(`${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx`, {
          params: {
            method: "DuanXinDengLu",
            ShouJiHaoMa2: this.tel,
            openid: this.openid,MoKuai:'ShuJuZhongXin',
            YanZhengMa: this.code,
          },
        })
        .then((res) => {
          if (res.data.Success) {
            // this.$u.toast('验证码已发送');
            // 通知验证码组件内部开始倒计时
            // this.$refs.uCode.start();
            // console.log(res);
            // return;
            this.$message({
              message: "登陆成功",
              type: "success",
            });
            this.NBWanStoken=res.data.CanShu1;
            this.storage.set('userToken',res.data.CanShu1);
            setTimeout(()=>{
              if(this.$isMobile()){
                this.$router.push("/mDataCenter");
              }else{
                this.$router.push("/");
              }
            },1000)
          } else {
            // this.$u.toast(res.data.Message);
            this.$message({
              message: res.data.Message,
              type: "warning",
            });
          }
        });

      }

    },
    cutDown() {
      this.setCutDown = true;
      let t = 60;
      var timer = null;
      clearInterval(timer);
      timer = setInterval(() => {
        if (t > 0) {
          t--;
          this.buttonMessage = t + "s后重新获取";
        } else {
          this.setCutDown = false;
          clearInterval(timer);
          this.buttonMessage = '获取短信验码';
        }
      }, 1000);
    },
    async checktel(val) {
      if (val == "") {
        this.$message({
          message: "请先输入手机号",
          type: "warning",
        });
        return false;
      }
       
      return true;
    },
    async getCode() {
      if (!this.setCutDown) {
        let flag = await this.checktel(this.tel);
        if (flag) {
          this.axios
            .get(
              `${this.$dt}/App/nbwtsp/XunDianAPP.ashx?method=FaSongDengLuYanZhengMa&ShouJiHaoMa2=` +
                this.tel
            )
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                // this.$u.toast('验证码已发送');
                // 通知验证码组件内部开始倒计时
                // this.$refs.uCode.start();
                this.$message({
                  message: "验证码已发送",
                  type: "success",
                });
                this.cutDown();
              } else {
                // this.$u.toast(res.data.Message);
                this.$message({
                  message: res.data.Message,
                  type: "warning",
                });
              }
            });
        }
      }


    },
  },
};
</script>

<style scoped lang="scss">


.container {
  background-image: url("~@/assets/images/bg.jpg") !important;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;
  .login-box {
    padding: 0;
    width: 330px;
    border-radius: 8px;
    border: 1px solid #eee;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.9);
    position: absolute;
    height: 250px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    /* z-index: 100; */
    h3 {
      font-size: 18px;
      text-align: left;
      color: #333;
      /* color: #fff; */
      height: 50px;
      line-height: 50px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      /* margin-bottom: 10px; */
      background-color: #fff;
      background: rgba(255, 255, 255, 0.4);
      -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.125);
      -moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.125);
      -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.125);
      -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.125);
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.125);
      text-align: center;
      margin: 0;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
    }
    .login-content {
      .login-item {
        color: #38adff;
        margin-bottom: 17px;
        font-size: 14px;
        outline: none;
        background-color: #fff;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 8px 10px;
        padding-right: 26px;
        height: 20px;
        line-height: 20px;
        border: 1px solid #d6d6d6;
        /* border: 1px solid rgba(255,255,255,.15); */
        width: 200px;
        box-sizing: content-box;
        -webkit-box-sizing: content-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        label {
          flex-shrink: 0;
        }
        input {
          border: none;
          outline: none;
          color: #38adff;
          font-size: 14px;
          margin-left: 10px;
          &::placeholder {
            font-size: 12px;
            color: #c8c9cc;
          }
          &:focus {
            border: none;
            outline: none;
          }
        }
        .tel-input{
          background-color:transparent;
        }
      }
      .login-code {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: none;
        padding: 0;
        height: 36px;
        width: 236px;
        justify-content: space-between;
        background: transparent;
        input {
          width: 40%;
          border: 1px solid #d6d6d6;
          height: 100%;
          padding-left: 20px;
          margin-left: 0;
          &:focus{
            border: 1px solid #d6d6d6;
          }
        }
        .code-btn {
          background-color: #5fa5f7;
          color: #fff;
          border-radius: 2px;
          height: 40px;
          width: 102px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
          cursor: pointer;
          // padding:0 10px;
        }
      }
    }
    .login-button {
      cursor: pointer;
      display: inline-block;
      color: #fff;
      height: 40px;
      line-height: 40px;
      width: 236px;
      text-align: center;
      border: 1px solid #2a88f6;
      background-color: #2a88f6;
      border-radius: 3px;
      font-size: 18px;
      letter-spacing: 4px;
      /* text-shadow: 1px 1px 0 rgba(0,0,0,.35); */
      opacity: 0.75;
      filter: alpha(opacity=75);
      transition: all 0.3s ease-in-out;
    }
  }
}
</style>
