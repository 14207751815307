import Vue from 'vue'
import Router from 'vue-router'
import pcContent from '@/components/navigation'
import pcKuCunZhengLi from '@/components/pc/index'
import DataCenter from '@/components/pc/DataCenter'
import mDataCenter from '@/components/mobile/mDataCenter'
import pcLogin from '@/components/pc/login'
import KuCunChaXun from '@/components/pc/KuCunChaXun'
import laundrylist from '@/components/pc/laundrylist'
import pcReport from '@/components/pc/report'
import mKuCunZhengLi from '@/components/mobile/index'
import mLogin from '@/components/mobile/login'
import mReport from '@/components/mobile/report'
import Avue from '@smallwei/avue';
import PivotTable from '@/components/pc/PivotTable'
import '@smallwei/avue/lib/index.css';
Vue.use(Avue);
Vue.use(Router)

export default new Router({
  routes: [
    // {
    //   path: '/',
    //   name: 'index',
    //   component: index
    // },

    {
      path: '/',
      name: 'DataCenter',
      component: DataCenter
    },
    {
      path: '/mDataCenter',
      name: 'mDataCenter',
      component: mDataCenter
    },
    {
      path: '/pcContent/:neme/:title',
      name: 'pcContent',
      component: pcContent
    },
    {
      path: '/PivotTable',
      name: 'PivotTable',
      component: PivotTable
    },
    {
      path: '/pc_index',
      name: 'pcKuCunZhengLi',
      component: pcKuCunZhengLi
    },
    {
      path: '/pcLogin',
      name: 'pcLogin',
      component: pcLogin
    },
    {
      path: '/pc_report',
      name: 'pcReport',
      component: pcReport
    },
    {
      path: '/KuCunChaXun',
      name: 'KuCunChaXun',
      component: KuCunChaXun
    },
    {
      path: '/laundrylist',
      name: 'laundrylist',
      component: laundrylist
    },
    {
      path: '/m_index',
      name: 'mKuCunZhengLi',
      component: mKuCunZhengLi
    },
    {
      path: '/m_login',
      name: 'mLogin',
      component: mLogin
    },
    {
      path: '/m_report',
      name: 'mReport',
      component: mReport
    }
  ]
})
