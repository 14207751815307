<template>
  <div class="pivot-wrapper">
    <Pivot ref="pivot" v-if="isShowbi" toolbar height="600" :global="globalT" :report="reportjs"
      v-bind:shareReportConnection="{
        url: 'https://olap.flexmonster.com:9500',
      }" v-bind:beforetoolbarcreated="customizeToolbar" _v-bind:licenseKey="'XXXX-XXXX-XXXX-XXXX-XXXX'"></Pivot>
    <div class="description-blocks">
      <p style="text-align:left;">
        您的查询限制：{{XianZhi}}<br />
        您的警告次数为：{{JingGaoCiShu}} 次 当超过9次时将会锁定。

      </p>
    </div>

    <el-dialog title="使用协议" :visible.sync="type1" class="avue-dialog avue-dialog--top" width="80%">
      <span style="color: red; font-size: large;">{{ShuoMing}}</span>
      <br />
      <span>
        尊敬的用户：<br /> 您好，为了方便您能更好的分析数据，本公司对您免费开放了本数据查询接口，出于数据安全、服务器稳定等情况请您尊守以下约定。
        <br />1、本数据只能查询从2018年至<span style="color: red; ">昨天</span>的数据,无法查询当天实时数据，如需要实时数据请到出入库流水账。
        <br />2、出于服务器稳定运行不要过分频繁查询报表，本报表支持EXCEL导出，对数据加工请导出操作。
        <br />3、<span style="color: red; ">供应商</span>、<span style="color: red; ">业务员</span>会有数据上的限制，如果您有限制请不要查询其他数据。
        <br />4、系统会记录您每次查询的条件，当您违规时系统会进就提示，超10次时会锁定无法查询。解锁需要老板签字，请不要违规。
        <br />5、本公司有权随时收回查询权限。
        <br />请多配合

      </span>
      <div class="avue-dialog__footer">
        <el-button @click="type1=false" type="primary">我能尊守约定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//Using the vue-flexmonster module (local registration):
//1. Importing the vue-flexmonster module:
//Uncomment the lines below:
import zhJson from "../../loc/zh.json";

import qs from 'qs'
export default {
  name: "PivotTable",
  data() {
    return {
      globalT: { localization: zhJson },
      reportjs: {},
      isShowbi: false,
      type1: true,
      ShuoMing: "",
      XianZhi: "无限制",
      JingGaoCiShu: 0

    };
  },
  mounted() {
    this.ListernerHandle();


    this.NBWanStoken = this.storage.get("userToken");
    if (!this.NBWanStoken) {
      this.$router.push("/pcLogin");
    }


    this.axios
      .get(
        `${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx?method=BIPeiZhiJSON&NBWanStoken=` +
        this.NBWanStoken
      )
      .then((res) => {

        if (res.data.Success) {
          this.isShowbi = true;
          this.reportjs = JSON.parse(res.data.Data);
          if (res.data.TotalCount > 0) {
            this.ShuoMing = res.data.Message;
          }
        } else {
          this.ShuoMing = res.data.Message;
          this.type1 = true;
          this.isShowbi = false;
        }
        this.JingGaoCiShu = res.data.TotalCount;
        if (res.data.CanShu1) {
          this.XianZhi = res.data.CanShu1;

        }

      });
  },
  methods: {
    customizeToolbar: function (toolbar) {
      toolbar.showShareReportTab = true;
    },
    ListernerHandle() {
      window.addEventListener(
        "message",
        (e) => {
          if (e && e.data && e.data.stringData) {
            var data = e.data;
            //console.log(this);
            if (data.stringData != undefined) {
              this.axios.post(
                `${this.$dt}/App/NBwtsp/ShuJuZhongXin.ashx?method=AddZhiNenTuBiaoChaXunJiLu`
                , qs.stringify({
                  NBWanStoken: this.NBWanStoken,
                  sql: data.stringData

                }))
                .then((res) => {

                  this.ShuoMing = res.data.Message;
                  if (res.data.Success) {
                    this.isShowbi = true;

                    if (res.data.TotalCount != this.JingGaoCiShu) {
                      this.JingGaoCiShu = res.data.TotalCount;
                      this.type1 = true;
                    }
                  } else {
                    this.type1 = true;
                    this.isShowbi = false;
                  }
                  this.JingGaoCiShu = res.data.TotalCount;


                });
            }

            //console.log("vue页面上接收数据", data.stringData);
          }
        },
        false
      );
    },
    beforeUnmount() {
      //在组件生命周期结束的时候销毁。
      window.removeEventListener("message", this.ListernerHandle, false);
    },
  },
  //2. Defining the component:
  //Uncomment the lines below:
};
</script>

<style>
  .pivot-wrapper{
    padding: 20px;
    background: #fdfeff;
  }
</style>
