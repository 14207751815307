<template>
  <div class="container-outer">
    <div class="container">
      <el-table
        :data="productList"
        border
        style="width: 1141px;"
        :style="productList.length==0?'height:100%':''"
        v-loading="loading"
        height="calc(100vh - 130px)"
        :row-class-name="tableRowClassName"
        size="small"
      >

        <el-table-column  prop="Zcpmc" label="拆除产品" width="300">
        </el-table-column>
        <el-table-column prop="ZkcT" label="能拆" width="80">
        </el-table-column>
        <el-table-column prop="Zkxts" label="不拆天数" width="80">
        </el-table-column>
        <el-table-column prop="Zxzl" label="箱装量" width="80">
        </el-table-column>
        <el-table-column prop="cpmc" label="待拆产品" width="300">
        </el-table-column>
        <el-table-column prop="kcT" label="库存" width="60"> </el-table-column>
        <el-table-column prop="kxts" label="可销天数" width="80">
        </el-table-column>
        <el-table-column prop="xzl" label="箱装量" width="80">
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <!-- <el-button @click="check(scope.row)" type="text" size="small"
              >查看</el-button
            > -->
            <!-- hasSave作为判断是否提交过的标志 提交过为true，查看，未提交过为false可输入 -->
            <el-button type="text" size="small" v-if="!scope.row.isTiJiao " @click="edit(scope.row)">{{
              scope.row.isTiJiao ? "" : "编辑"
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-dialog title="库存调整" :visible.sync="dialogFormVisible">
          <div class="dialog-content">
            <div class="dialog-moren">
              <div class="moren-top">
                <div class="moren-kuwei">
                  默认库位: {{ dialogMessage.Ccp.MoRenKuWei }}
                </div>
                <div class="moren-zengjia">
                  本次增加: <span>{{ addXiangshu }}</span
                  >箱
                </div>
              </div>
              <div class="moren-list">
                <div
                  class="moren-item"
                  v-for="(item, index) in dialogMessage.Ccp.kwkclist"
                  :key="index"
                >
                  <div class="item-leixing">
                    <span class="leixing-kw">KW: {{ item.kwbh }}</span>
                    <span class="leixing-ph">PH: {{ item.ph }}</span>
                  </div>
                  <div class="item-xiangshu">{{ item.sjkcxs }}</div>
                </div>
              </div>
            </div>

            <div :class="showBtn ? 'dialog-jihua' : 'dialog-moren'">
              <div class="jihua-top">
                <div class="jihua-kuwei">
                  计划出库: <span>{{ dialogMessage.Zcp.JiHuaChuKu }}</span>
                </div>
                <!-- <div class="moren-zengjia">本次增加:10箱</div> -->
              </div>
              <div class="jihua-list">
                <div
                  class="jihua-item"
                  v-for="(item, index) in dialogMessage.Zcp.kwkclist"
                  :key="index"
                >
                  <div class="item-leixing">
                    <span class="leixing-kw">KW: {{ item.kwbh }}</span>
                    <span class="leixing-ph">PH: {{ item.ph }}</span>
                  </div>
                  <div class="item-xiangshu">{{ item.sjkcxs }}</div>
                  <el-input
                    placeholder="请输入箱数"
                    prefix-icon="el-icon-edit el-input__icon"
                    v-model="item.xiangshu"
                    v-if="showBtn"
                    type="number"
                    @input="changAddXiangshu($event, item)"
                    :validate-event="validate"
                    :max="xzlMax"
                    min="0"
                  >
                  </el-input>
                </div>
              </div>
            </div>
          </div>
          <div slot="footer" class="dialog-footer" v-if="showBtn">
            <!-- <el-button @click="dialogFormVisible = false">取 消</el-button> -->
            <el-button type="primary" @click="toSave()">保 存</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="output-btn">
      <el-button type="primary" icon="el-icon-download" @click="output()"
        >导出</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xiangshu: "",
      showBtn: true,
      validate: true,
      addXiangshu: 0,
      xzl: 0,
      Zxzl: 0,
      xzlMax: 0,
      cpbh: "",
      Zcpbh: "",
      cpmc: "",
      Zcpmc: "",
      MoRenKuWei: "",
      TiaoZhengData: "",
      loading: true,
      productList: [],
      dialogMessage: {
        Ccp: {
          MoRenKuWei: "",
          kwkclist: [],
        },
        Zcp: {
          JiHuaChuKu: 0,
          kwkclist: [],
        },
      },
      dialogFormVisible: false,
      saveItem:{},
      saveIndex:[]
    };
  },
  mounted() {
    this.getBaoBiao();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
        for(var i = 0;i<this.saveIndex.length;i++){
           if (rowIndex == this.saveIndex[i]) {
          return 'success-row';
        }
        }
        return '';
      },
    // 错误消息提示
    openError(errMessage) {
      this.$message.error(errMessage);
    },
    //确认消息提示
    openConfirm(confirmMsg) {
        this.$alert(confirmMsg, {
          confirmButtonText: '确定'
        });
      },
    // check(row) {
    //   this.dialogFormVisible = true;
    //    this.showBtn = false;
    //   console.log(row);
    // },
    //加载表格
    getBaoBiao() {
      // console.log(this.axios);
      this.axios
        .get(`${this.$dt}/App/NBwtsp/NBwtspAjax.ashx?method=ChaiXiangBaoBiao`)
        .then((res) => {
          console.log(res);
          this.productList = res.data;
          this.loading = false;
        });
    },
    //获取库存
    getKuCun() {},
    edit(row) {
      this.addXiangshu = 0;
      this.TiaoZhengData = [];
      // hasSave作为判断是否提交过的标志 提交过为true，查看，未提交过为false可输入
      this.cpbh = row.cpbh;
      this.Zcpbh = row.Zcpbh;
      this.cpmc = row.cpmc;
      this.Zcpmc = row.Zcpmc;
      if (row.hasSave) {
        this.showBtn = false;
      } else {
        this.showBtn = true;
        this.Zxzl = row.Zxzl; //本次增加
        this.xzl = row.xzl; //箱装量

        // console.log(this.xzlMax);
      }
      this.axios
        .get(
          `${this.$dt}/App/NBwtsp/NBwtspAjax.ashx?method=ChaiXiangKuWeiKuCun&cpbh=${this.cpbh}&Zcpbh=${this.Zcpbh}`
        )
        .then((res) => {
          // console.log(res);
          this.dialogMessage = res.data;
          this.MoRenKuWei = this.dialogMessage.Ccp.MoRenKuWei;
        });
      this.dialogFormVisible = true;
      this.saveItem = row;
    },
    //用户输入箱数，改变增加箱装量
    changAddXiangshu(e, item) {
      this.addXiangshu = 0;
      var num = Number(e);
      this.xzlMax = Math.floor(item.sjkyl / this.Zxzl); //输入最大值
      // 判断是否超过最大输入
      if (e >= this.xzlMax) {
        // console.log(e);
        // this.xzlMax = Math.floor(this.xzlMax);
        item.xiangshu = this.xzlMax;
        num = this.xzlMax;
      }
      // 改变本次增加
      item.addXiangshu = (num * this.Zxzl) / this.xzl;
      item.sl = item.xiangshu * this.Zxzl;
      this.dialogMessage.Zcp.kwkclist.forEach((item,index,array)=>{
        // console.log(parseInt(item.addXiangshu));
        if(item.addXiangshu){
          this.addXiangshu += Number(item.addXiangshu);
        }
      })
    },
    //每次输入箱数之前的判断，初始化本次增量
    initXiangshu(item) {
      if (!item.xiangshu) {
        this.addXiangshu = 0;
      } else {
        this.addXiangshu = (item.xiangshu * this.Zxzl) / this.xzl;
      }
      // console.log(item.xiangshu);
    },
    //保存
    toSave() {
      this.dialogMessage.Zcp.kwkclist.forEach((item, index, array) => {
        var newItem = {};
        if (item.xiangshu && item.xiangshu > 0) {
          // console.log(item);
          newItem.XiangShu = item.xiangshu;
          newItem.ChaiHouXiangShu = item.addXiangshu;
          newItem.kwbh = item.kwbh;
          newItem.ph = item.ph;
          newItem.sjkcxs = item.sjkcxs;
          this.TiaoZhengData.push(newItem);
        }
      });
      this.TiaoZhengData = JSON.stringify(this.TiaoZhengData)
      // console.log(this.TiaoZhengData);
      // return;
      this.dialogFormVisible = false;
      this.axios
        .get(
          `${this.$dt}/App/NBwtsp/NBwtspAjax.ashx`,
          {params:{
            method:"AddGeXiangKuCunDiaoZhengBiao",
            cpbh: this.cpbh,
            Zcpbh: this.Zcpbh,
            cpmc: this.cpmc,
            Zcpmc: this.Zcpmc,
            xzl: this.xzl,
            Zxzl: this.Zxzl,
            MoRenKuWei: this.MoRenKuWei,
            TiaoZhengData: this.TiaoZhengData,
          }}
        )
        .then((res) => {
          if(res.data.Success){
            this.openConfirm(res.data.Message)
            this.productList.forEach((item,index,array)=>{
              if(this.saveItem == item){
                item.isTiJiao = true;
                this.saveIndex.push(index);
                this.$forceUpdate();
              }
            })
          }else{
            this.openError(res.data.Message)
          }
          // console.log(res);
        });
    },
    //导出
    output() {
      this.axios
        .get(`${this.$dt}/App/NBwtsp/NBwtspAjax.ashx?method=ShengChengTiaoZhengDan`)
        .then((res) => {

          if (res.data.Success == false) {
            this.openError(res.data.Message);
          } else {
            // console.log(`/api`+res.data.Message);
            window.open(`https://oa.nbwans.com`+res.data.Message);
            // window.open(res.data.Message);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.container-outer {
  position: relative;
  padding: 30px 0 60px;
  width: 1260px;
  margin: 0 auto;
}
.output-btn {
  position: absolute;
  right: 0;
  bottom: 10px;
}
.container {
  width: 1260px;
  overflow: auto;
  padding: 20px 0;
  height: calc(100vh - 130px);
  background: #fff;
}
.dialog-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dialog-moren,
.dialog-jihua {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
}
.dialog-jihua {
  width: 50%;
}
.moren-top,
.jihua-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  font-weight: bold;
}
// .jihua-kuwei{
//   font-size: 22px;
//   color: red;
// }
.moren-zengjia {
  margin-left: 20px;
}
.moren-zengjia span,.jihua-kuwei span {
  color: red;
  font-size: 18px;
  margin-right: 4px;
}
.moren-list,
.jihua-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.moren-item,
.jihua-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 20px;
  width: 100%;
}
.item-leixing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  margin-right: 40px;
}
.leixing-kw {
  margin-bottom: 6px;
}
.item-xiangshu {
  flex-shrink: 0;
  margin-right: 20px;
  width: 70px;
}
.jihua-item /deep/ .el-input__inner {
  padding-right: 0;
}
.container /deep/ .el-dialog__footer {
  text-align: center;
}
.container /deep/ .el-dialog__headerbtn {
  display: none;
}
.container /deep/ .el-dialog__header {
  background: #409eff;
  .el-dialog__title {
    color: #fff;
  }
}
.container /deep/ .el-dialog__body {
  border-bottom: 1px solid #eee;
}
.container /deep/ .el-table {
  margin: 0 auto;
}

  .container /deep/ .el-table .success-row {
    background: #f0f9eb;
  }
</style>
